import { useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Scroll, useScroll } from "../components/ScrollControls"

export const StaticPage = ({ startOffset, children, ...props }) => {
  const state = useThree()
  const { width, height } = state.viewport.getCurrentViewport(state.camera)

  return (
    <Scroll>
      <group position={[0, -height * startOffset, 0]}>
        <group {...props}>
          {/* <mesh>
            <planeGeometry args={[width, height]} />
            <meshBasicMaterial color={['red', 'green', 'blue'][startOffset % 3]} transparent opacity={0.25}/>
          </mesh> */}
          {children}
        </group>
      </group>
    </Scroll>
  )
}

export const StaticHtmlPage = ({ startOffset, children }) => {
  return (
    <div style={{ position: 'absolute', top: `${100 * startOffset}vh` }}>
      {children}
    </div>
  )
}

export const FixedPage = ({ children, ...props }) => {
  return (
    <group {...props}>
      {/* <mesh>
        <planeGeometry args={[width / 2, height]} />
        <meshBasicMaterial color='indigo' />
      </mesh> */}
      {children}
    </group>
  )
}

export const StickyPage = ({ startOffset, endOffset, children, ...props }) => {
  const group = useRef()
  const scroll = useScroll()
  const state = useThree()
  const { height } = state.viewport.getCurrentViewport(state.camera)

  useFrame(() => {
    const position = scroll.offset * (scroll.pages - 1)
    let newPosition = 0

    if (position < startOffset) {
      newPosition = height * position
    } else if (position > startOffset && position < endOffset) {
      newPosition = startOffset * height
    } else if (position > endOffset) {
      newPosition = height * position - ((endOffset - startOffset) * height)
    }

    group.current.position.y = newPosition
  })

  return (
    <group ref={group}>
      <group position={[0, -height * startOffset, 0]}>
        <group {...props}>
          {/* <mesh>
            <planeGeometry args={[width / 2, height]} />
            <meshBasicMaterial color='violet' />
          </mesh> */}
          {children}
        </group>
      </group>
    </group>
  )
}