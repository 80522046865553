import { useRef, useMemo, useState } from "react"
import * as THREE from "three"
import { useFrame } from "@react-three/fiber"
import {
  MeshTransmissionMaterial,
  MeshDiscardMaterial,
  useGLTF,
  useTexture,
} from "@react-three/drei"
import {
  EffectComposer,
  SelectiveBloom,
} from "@react-three/postprocessing"

import { useScroll } from "../components/ScrollControls"
import useStore from '../store'

import { StaticPage } from '../components/Pages'

// in out quad
// const ease = (t) => (t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2)
// linear
const ease = (t) => t

export default function Contact({ startOffset }) {
  const { wordmarkExplosion } = useStore()
  
  const DepthOfFieldRef = useRef()

  const { nodes } = useGLTF("/_vite/models/ParadowskiLogoUV.glb")
  const gradientTexture = useTexture("/_vite/textures/LogoGradient.jpg")

  gradientTexture.offset.x = -0.5

  const backgroundColor = useMemo(() => new THREE.Color("#000"))

  const transmissionMeshRef = useRef()
  const stripeMeshRef = useRef()
  const stripeMaterialRef = useRef()
  const lightRef = useRef()

  const [renderMeshTransmissionMaterial, setRenderMeshTransmissionMaterial] =
    useState(false)
  const animate = useRef(false)
  const animationProgress = useRef(0)

  const scroll = useScroll()

  useFrame((state) => {
    const t = (1 + Math.sin(state.clock.elapsedTime)) / 2
    const color = new THREE.Color(`hsl(${t * 255}, 100%, 50%)`)
    const t2 = t * 20 + 2
    stripeMaterialRef.current.color.setRGB(2 + t * 20, 1, 20 + t * 50)
    // stripeMaterialRef.current.color.setRGB(color.r, color.g, color.b)
    // stripeMaterialRef.current.color.setRGB(color.r * t2, color.g * t2, color.b * t2)

    const position = scroll.offset * (scroll.pages - 1)

    if (position > startOffset - 1 && !renderMeshTransmissionMaterial) {
      setRenderMeshTransmissionMaterial(true)
    }

    if (position > startOffset - 1 + 0.5 && !animate.current) {
      animate.current = true
    }

    if (animate.current) {
      if (animationProgress.current < 1) {
        animationProgress.current += state.clock.getDelta() * 10
        if (animationProgress.current > 1) animationProgress.current = 1

        const easedProgress = ease(animationProgress.current)

        gradientTexture.offset.x = -0.5 + easedProgress * 1.5
      } else {
        gradientTexture.offset.x = 1
      }

      // gradientTexture.offset.x = 1 + 1.5 * (position - startOffset - .4)
    }
  })

  return (
    <StaticPage startOffset={startOffset}>
      <group scale={2} position-y={1}>
        <mesh
          receiveShadow
          castShadow
          geometry={nodes.PCLOGO.geometry}
          ref={transmissionMeshRef}
        >
          {renderMeshTransmissionMaterial && (
            <MeshTransmissionMaterial
              backside
              backsideThickness={0.005}
              thickness={0.005}
              ior={0.9}
              iridescenceIOR={2.33}
              transmission={2.0}
              roughness={0.1}
              // chromaticAberration={0.025}
              background={backgroundColor}
              transparent
              alphaMap={gradientTexture}
              envMapIntensity={0}
            />
          )}
          {!renderMeshTransmissionMaterial && <MeshDiscardMaterial />}
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.StripeGeo.geometry}
          ref={stripeMeshRef}
        >
          <meshBasicMaterial
            ref={stripeMaterialRef}
            toneMapped={false}
            transparent
            alphaMap={gradientTexture}
          />
        </mesh>
      </group>
      <ambientLight intensity={0} ref={lightRef} />
      <EffectComposer>
        <SelectiveBloom
          luminanceThreshold={0.75}
          mipmapBlur
          lights={[lightRef]}
          selection={[stripeMeshRef, transmissionMeshRef]}
          intensity={0.25}
          luminanceSmoothing={1.0}
        />
      </EffectComposer>
    </StaticPage>
  )
}

useGLTF.preload("/_vite/models/ParadowskiLogoUV.glb")
