import React, { useRef, useEffect, useLayoutEffect, useState, useContext, createContext } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import {
  Vector3,
  Path,
  GameEntity,
  EntityManager,
  Vehicle,
  AlignmentBehavior,
  CohesionBehavior,
  SeparationBehavior,
  WanderBehavior,
  FollowPathBehavior,
  ObstacleAvoidanceBehavior,
  Smoother,
} from 'yuka'


const obstacles = []

const obstacle = new GameEntity()

obstacle.position.copy(new Vector3(0, 0, 0))
obstacle.boundingRadius = 1
obstacles.push(obstacle)

const segments = 32

const waveFrequency = 2
const waveHeight = 1
const heightOffset = 0

const radius = 2.25

const createOrbitPath = () => {
  const path = new Path()
  path.loop = true

  const points = []

  for (let angle = 0; angle < Math.PI * 2; angle += Math.PI * 2 / segments) {
    const offsetAngle = angle + Math.PI * 3 / 2
    const x = Math.cos(offsetAngle) * radius
    let z = -Math.sin(offsetAngle) * radius
    // z = z > 0 ? z * 1.25 : z
    const y = Math.sin(offsetAngle * waveFrequency) * waveHeight + heightOffset
    points.push(new Vector3(x, y, z))
  }

  points.forEach((point) => {
    path.add(point)
  })

  return path
}

const path = createOrbitPath()

export const points = []

for (let i = 0; i < path._waypoints.length; i++) {

  const waypoint = path._waypoints[i];

  points.push(waypoint.x, waypoint.y, waypoint.z);
}

const params = {
  alignment: 1.5,
  cohesion: 1,
  separation: 0.5
}

const baseMaxSpeed = 1.5

const target = new Vector3()

const alignmentBehavior = new AlignmentBehavior()
const cohesionBehavior = new CohesionBehavior()
const separationBehavior = new SeparationBehavior()

alignmentBehavior.weight = params.alignment
cohesionBehavior.weight = params.cohesion
separationBehavior.weight = params.separation

const context = createContext()

function applyDefaultSteeringBehaviors(vehicle, viewport) {
  vehicle.steering.clear()
  // // Flocking
  vehicle.updateNeighborhood = true
  vehicle.neighborhoodRadius = 1
  vehicle.boundingRadius = 0.25
  vehicle.steering.add(alignmentBehavior)
  vehicle.steering.add(cohesionBehavior)
  vehicle.steering.add(separationBehavior)

  // WanderBehavior
  // const wanderBehavior = new WanderBehavior()
  // wanderBehavior.weight = 0.05
  // vehicle.steering.add(wanderBehavior)

  const path = createOrbitPath()

  const followPathBehavior = new FollowPathBehavior(path, 1.0)
  vehicle.steering.add(followPathBehavior)

  const obstacleAvoidanceBehavior = new ObstacleAvoidanceBehavior(obstacles, 1.0)
  vehicle.steering.add(obstacleAvoidanceBehavior)

  vehicle.position.x = -(viewport.width/2) + (-Math.random() * 2) - 1
  vehicle.position.y = (0.5 - Math.random()) * 3 + 2
  vehicle.position.z = (0.5 - Math.random()) + 2
}

export function Manager({ children }) {
  const [entityManager] = useState(() => new EntityManager(), [])

  useEffect(() => {
    const vehicles = entityManager.entities.filter((item) => item instanceof Vehicle)

    vehicles.forEach((vehicle) => {
      // Initialization
      vehicle.maxSpeed = baseMaxSpeed
      vehicle.smoother = new Smoother(64)

      // applyDefaultSteeringBehaviors(vehicle)
    })

    entityManager.add(obstacle)
    obstacles.push(obstacle)
  }, [])

  useFrame((state, delta) => {
    // clamp delta -- steering behaviors are sensitive to large values
    delta = Math.min(delta, 0.1)
    entityManager.update(delta)
  })

  // scale max speed based on pitch to emulate gravity
  useFrame((state, delta) => {
    entityManager.entities.forEach(entity => {
      if (entity instanceof Vehicle) {
        const euler = entity.rotation.toEuler(target)
        const pitch = euler.x

        const gravityInfluence = 1.0
        entity.maxSpeed = Math.max(baseMaxSpeed, baseMaxSpeed + Math.sin(pitch) * gravityInfluence)
      }
    })
  })

  return <context.Provider value={entityManager}>{children}</context.Provider>
}

export function useYuka({ type = GameEntity, position = [0, 0, 0] }) {
  const ref = useRef()
  const entityManager = useContext(context)
  const [entity] = useState(() => new type())
  const { viewport } = useThree()

  useEffect(() => {
    entity.position.set(...position)

    entity.setRenderComponent(ref, (entity) => {
      ref.current.position.copy(entity.position)
      ref.current.quaternion.copy(entity.rotation)
    })

    entity.applyDefaultSteeringBehaviors = (entity) => {
      return applyDefaultSteeringBehaviors(entity, viewport)
    }

    entityManager.add(entity)

    return () => entityManager.remove(entity)
  }, [viewport.width])

  return [ref, entity]
}