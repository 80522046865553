import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { Instances, Instance, Text, Line, Float, useCursor, useGLTF, useAnimations } from '@react-three/drei'
import { Vehicle } from 'yuka'
import { Manager, useYuka, points } from '../hooks/useYuka'
import { Scroll, useScroll } from './ScrollControls'
import { StaticPage } from './Pages'

const VideoPlane = ({
  startOffset,
  endOffset,
  up = new THREE.Vector3(0, 1, 0),
  vec3 = new THREE.Vector3,
  quat = new THREE.Quaternion,
}) => {
  const ref = useRef()
  const { nodes, materials } = useGLTF('/_vite/models/Airplane3Folded.glb')
  const { viewport, camera, size } = useThree()
  const scroll = useScroll()
  const startZ = -2
  const startZViewport = viewport.getCurrentViewport(camera, [0, 0, startZ])

  let curve

  if (size.width < 736) {
    curve = new THREE.CatmullRomCurve3([
      new THREE.Vector3(startZViewport.width / 2 + 1, startZViewport.height / 2, -2),
      new THREE.Vector3(0, -2, -4),
      new THREE.Vector3(-viewport.width / 2 - 1, -viewport.height / 2, 0),
    ], false, 'chordal')
  } else {
    curve = new THREE.CatmullRomCurve3([
      new THREE.Vector3(startZViewport.width / 2 + 1, startZViewport.height / 2, -2),
      new THREE.Vector3(0, 0, -8),
      new THREE.Vector3(-0.4 * viewport.width, -1, -6),
      new THREE.Vector3(-viewport.width / 2 - 1, -viewport.height / 2, 0),
    ], false, 'chordal')
  }
  
  // const points = curve.getPoints(64)

  // set ref position to point along curve according to scroll position
  useFrame((state, delta) => {
    const position = scroll.offset * (scroll.pages - 1)
    let progress = 0
    if (position < startOffset || position > endOffset) progress = 0
    else if (position > endOffset) progress = 1
    else {
      progress = (position - startOffset) / (endOffset - startOffset)
    }

    const point = curve.getPointAt(progress)
    const tan = curve.getTangent(progress).normalize()
    const quaternion = quat.setFromUnitVectors(up, vec3.copy(tan))

    ref.current.position.copy(point)
    ref.current.quaternion.copy(quaternion)
  })

  return (
    <group>
      {/* <Line points={points} dashed dashScale={20} /> */}
      <group
        ref={ref}
        >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={materials['Material.002']}
          rotation-x={Math.PI / 2}
          rotation-z={-Math.PI / 2}
          scale={4}
        />
      </group>
    </group>
  )
}

const FlockAirplane = ({ index, active = false, ...props }) => {
  const [entityRef, entity] = useYuka({ type: Vehicle, ...props })
  const instanceRef = useRef()
  const { viewport } = useThree()

  useEffect(() => {
    if (active) {
      entity.applyDefaultSteeringBehaviors(entity)
      entity.active = true
    } else if (entity.active) {
      entity.applyDefaultSteeringBehaviors(entity)
      entity.active = false
    }
  }, [active, viewport.width])

  return (
    <group ref={entityRef} position-z={-2.25}>
      <Instance
        ref={instanceRef}
        rotation-x={-Math.PI}
        rotation-z={-Math.PI * 0.9}
        scale={1.25}
      />
    </group>
  )
}

const Flock = ({ startOffset, ...props }) => {
  const { nodes, materials } = useGLTF('/_vite/models/Airplane3Folded.glb')
  const scroll = useScroll()
  const [active, setActive] = useState(false)

  useFrame((state, delta) => {
    const position = scroll.offset * (scroll.pages - 1)
    if (position > startOffset && !active) {
      setActive(true)
    } else if (position < startOffset && active) {
      setActive(false)
    }
  })

  return (
    <group {...props}>
      <Manager>
        <Instances
          limit={8}
          range={8}
          geometry={nodes.Plane.geometry}
          material={materials['Material.002']}
          frustumCulled={false}
        >
          {Array(8).fill().map((_, index) => (
            <FlockAirplane key={index} active={active} />
          ))}
        </Instances>
        {/* <Line points={points} lineWidth={1} dashed dashScale={20}/> */}
      </Manager>
    </group>
  )
}

const PaperPlanes = ({ startOffset, endOffset }) => {
  return (
    <group>
      <VideoPlane startOffset={startOffset - 0.5} endOffset={endOffset - 1} />
      <StaticPage startOffset={endOffset}>
        <Flock startOffset={endOffset - 1} position-y={1} />
      </StaticPage>
    </group>
  )
}

export default PaperPlanes