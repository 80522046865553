import { StaticHtmlPage } from "./Pages"
import { ContactHtmlTriggerPercentage } from "./ScrollPercentage"
import { useScroll } from "../components/ScrollControls"
import { useState, useRef } from "react"
import { useFrame } from "@react-three/fiber"

const socials = [
  {
    label: "linkedin",
    href: "https://www.linkedin.com/company/paradowski-creative",
  },
  { label: "x", href: "https://twitter.com/paracreative" },
  { label: "facebook", href: "https://www.facebook.com/paradowskicreative" },
  { label: "instagram", href: "https://instagram.com/paradowskicreative" },
  { label: "tiktok", href: "https://www.tiktok.com/@paracreative" },
]

export default function ContactHtml({ startOffset }) {
  const [htmlAnimationTriggered, setHtmlAnimationTriggered] = useState(false)
  const scroll = useScroll()
  const htmlRef = useRef()

  useFrame(() => {
    if (scroll && htmlRef) {
      const handleScroll = () => {
        const triggerPoint = scroll.offset * scroll.pages
        if (
          triggerPoint > startOffset + ContactHtmlTriggerPercentage &&
          !htmlAnimationTriggered
        ) {
          setHtmlAnimationTriggered(true)
        }
      }
      handleScroll()
    }
  })
  return (
    <StaticHtmlPage startOffset={startOffset}>
      <section className={`contact inner-wrapper `} id="contact">
        <h2
          className={`heading ${
            htmlAnimationTriggered ? "loading-text" : "global-invisible"
          }`}
        >
          What can we help you bring to life?
        </h2>
        <a
          className={`button ${
            htmlAnimationTriggered ? "loading-text" : "global-invisible"
          }`}
          href="mailto:info@paradowski.com"
        >
          Throw us an idea
        </a>
        <p
          className={`address ${
            htmlAnimationTriggered ? "loading-text" : "global-invisible"
          }`}
        >
          349 Marshall Ave. Ste. 300
          <br />
          St. Louis, MO 63119
          <br />
          (314) 241 2150
          {/* <a href='tel:+1 (314) 241 2150'>(314) 241 2150</a> */}
        </p>
        <a
          href="mailto:jobs@paradowski.com"
          className={`jobs ${
            htmlAnimationTriggered ? "loading-text" : "global-invisible"
          }`}
        >
          jobs@paradowski.com
        </a>
        <footer>
          <ul
            className={`socials ${
              htmlAnimationTriggered ? "loading-text" : "global-invisible"
            }`}
          >
            {socials.map(({ label, href }, i) => (
              <li key={i}>
                <a
                  className={`social ${label}`}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={label}
                >
                  <img src={`./_vite/socials/${label}.svg`} alt={`label`} />
                </a>
              </li>
            ))}
          </ul>
        </footer>
      </section>
    </StaticHtmlPage>
  )
}
