import { useState, useRef, useEffect, useMemo } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import { StaticHtmlPage } from "./Pages"
import { AboutHtmlTriggerPercentage } from "./ScrollPercentage"
import { useScroll } from "../components/ScrollControls"

const ClientElement = ({ client }) => {
  const { size } = useThree()

  const [focused, setFocused] = useState(false)
  const ref = useRef()
  window.ref = ref

  useEffect(() => {
    // use intersection observer to determine if element is vertically centered
    const margin = (size.height - ref.current.clientHeight) / 2 - 12
    const observer = new IntersectionObserver(
      ([entry]) => {
        setFocused(entry.isIntersecting)
      },
      {
        root: null,
        rootMargin: `-${margin}px 0px -${margin}px 0px`,
        threshold: 0.5,
      }
    )

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [size.height, size.width, ref.current?.clientHeight])

  return (
    <li
      className={`client ${focused ? "focused" : ""}`}
      ref={ref}
    >
      <p>
        {client.name}
      </p>
      {client.imgSrc && (
        <img
          className="image"
          src={client.imgSrc}
          alt={`${client.name} logo`}
        />
      )}
    </li>
  )
}

export default function AboutHtml({ startOffset }) {
  const clients = [
    { name: "Purina", imgSrc: "./_vite/stickers/purina.png" },
    { name: "Adidas", imgSrc: "./_vite/stickers/adidas.png" },
    { name: "Sesame Street", imgSrc: "./_vite/stickers/sesame-street.png" },
    { name: "Enterprise Mobility", imgSrc: "./_vite/stickers/enterprise-mobility.png" },
    { name: "Bayer", imgSrc: "./_vite/stickers/bayer.png" },
    { name: "Sony", imgSrc: "./_vite/stickers/sony.png" },
    { name: "Saint Louis Zoo", imgSrc: "./_vite/stickers/st-zoo.png" },
    { name: "Climate", imgSrc: "./_vite/stickers/climate-fieldview.png" },
    { name: "Verizon", imgSrc: "./_vite/stickers/verizon.png" },
    { name: "Imo’s Pizza", imgSrc: "./_vite/stickers/imos-pizza.png" },
  ]

  const [htmlAnimationTriggered, setHtmlAnimationTriggered] = useState(false)
  const scroll = useScroll()
  const htmlRef = useRef()

  useFrame(() => {
    if (scroll && htmlRef) {
      const handleScroll = () => {
        const triggerPoint = scroll.offset * scroll.pages
        if (
          triggerPoint > AboutHtmlTriggerPercentage + startOffset &&
          !htmlAnimationTriggered
        ) {
          setHtmlAnimationTriggered(true)
        }
      }
      handleScroll()
    }
  })

  return (
    <StaticHtmlPage startOffset={startOffset}>
      <section className={`about inner-wrapper`} id="about">
        <div className={`content ${
            htmlAnimationTriggered ? "loading-text" : "global-invisible"
          }`}>
          <h2 className='heading h3'>
            Paradowski is an independent creative agency guided by one core
            principle: there’s no creativity without play.
          </h2>
          <p className='subhead'>
            Mixing rich storytelling and design with emerging technology, we
            pursue ideas and mediums that help our clients’ brands rise above
            the noise. 
          </p>
          <h3 className='h4'>
            Select Clients
          </h3>
          <ul>
            {clients.map((client, i) => (
              <ClientElement key={i} client={client} />
            ))}
          </ul>
        </div>
      </section>
    </StaticHtmlPage>
  )
}
