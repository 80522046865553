import { useEffect, useRef, useState, useMemo } from 'react'
import { useThree } from '@react-three/fiber'
import { ScrollControls, Scroll } from '../components/ScrollControls'
import { create } from 'zustand'

import useStore from '../store'

import FloatingWordmark from '../components/FloatingWordmark'
import FloatingWordmarkHtml from '../components/FloatingWordmarkHtml'
import Teaser from '../components/Teaser'
import TeaserHtml from '../components/TeaserHtml'
import AboutHtml from '../components/AboutHtml'
import Contact from '../components/Contact'
import ContactHtml from '../components/ContactHtml'
import PaperPlanes from './PaperPlanes'

const initialPages = 5;

const initialStartOffsets = {
  floatingWordmark: 0,
  teaser: 1,
  about: 3,
  contact: 4,
};

const usePagesStore = create((set) => ({
  pages: initialPages,
  startOffsets: { ...initialStartOffsets },
  setPages: (pages) => set({ pages }),
  setStartOffsets: (startOffsets) => set({ startOffsets }),
}));

export default function ScrollableContent() {
  const scrollRef = useRef();
  const {
    pages,
    startOffsets,
    setPages,
    setStartOffsets
  } = usePagesStore()

  const viewport = useThree((state) => state.viewport)

  const { customLoaderActive, teaserVideoFullscreen } = useStore()

  useEffect(() => {
    if (scrollRef?.current?.children) {
      const pageHeight = window.innerHeight

      let overflow = 0

      let newStartOffsets = { ...initialStartOffsets }

      Array.prototype.slice
        .call(scrollRef.current.children)
        .forEach((child, childIndex) => {
          const childHeight = child.getBoundingClientRect().height
          if (childHeight > pageHeight) {
            const childOverflow = childHeight / pageHeight - 1
            Object.keys(startOffsets).forEach((key, pageIndex) => {
              if (pageIndex > childIndex) {
                newStartOffsets[key] += childOverflow
              }
            })
            overflow += childHeight / pageHeight - 1
          }
        })

      if (overflow && !teaserVideoFullscreen) {
        setPages(initialPages + overflow)
        setStartOffsets(newStartOffsets)
      }
    }
  }, [viewport, scrollRef.current, customLoaderActive, setPages, setStartOffsets])

  return (
    <ScrollControls eps={0.000001} damping={0.2} pages={pages}>
      {/* visualize pages */}
      {/* {Array(Math.round(pages)).fill(null).map((_, i) => (
        <StaticPage startOffset={i} key={i}>
          <Text fontSize={0.1}>Static {i}</Text>
        </StaticPage>
      ))} */}
      <FloatingWordmark startOffset={startOffsets.floatingWordmark} endOffset={0.25} />
      <Teaser startOffset={startOffsets.teaser} endOffset={startOffsets.contact} />
      <PaperPlanes startOffset={startOffsets.about} endOffset={startOffsets.contact} />
      <Contact startOffset={startOffsets.contact} />
      <Scroll html>
        <div ref={scrollRef}>
          <FloatingWordmarkHtml startOffset={startOffsets.floatingWordmark} />
          <TeaserHtml startOffset={startOffsets.teaser} />
          <AboutHtml startOffset={startOffsets.about} />
          <ContactHtml startOffset={startOffsets.contact} />
        </div>
      </Scroll>
    </ScrollControls>
  );
}
