import { Suspense, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { PerformanceMonitor, OrbitControls } from '@react-three/drei'
import { useSearch } from 'wouter'

// TEMP
import { Perf } from 'r3f-perf'

import useStore from '../store'
import CustomLoader from '../components/CustomLoader'

import ScrollableContent from '../components/ScrollableContent'

const maxDpr = 1.5
const minDpr = 0.5

const Home = () => {
  const [dpr, setDpr] = useState(maxDpr)
  const search = useSearch()

  return (
    <div className={`outer-wrapper ${search.includes('debug') ? 'debug' : ''}`}>
      <Canvas dpr={dpr}>
        <Suspense fallback={null}>
          <PerformanceMonitor
            onChange={(change) => {
              let newDpr = Math.round(.5 + 1.5 * change.factor * 10) / 10

              if (newDpr < dpr) {
                if (change.fps < 45) {
                  newDpr = Math.max(newDpr, minDpr)
                  // console.info('newDpr', newDpr, 'fps', change.fps, 'factor', change.factor)
                  setDpr(newDpr)
                }
              } else {
                newDpr = Math.min(newDpr, maxDpr)
                // console.info('newDpr', newDpr, 'fps', change.fps, 'factor', change.factor)
                setDpr(newDpr)
              }
            }}
          >
            <ScrollableContent />
            <spotLight position={[20, 20, 10]} penumbra={1} castShadow angle={0.2} />
          </PerformanceMonitor>
        </Suspense>
        {/* <OrbitControls /> */}
        {search.includes('debug') && <Perf />}
      </Canvas>
      <CustomLoader minimumDisplayTime={1000}/>
      </div>
  )
}

export default Home
