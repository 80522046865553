import { create } from 'zustand'

const useStore = create((set) => ({
  customLoaderActive: true,
  setCustomLoaderActive: (customLoaderActive) => set({ customLoaderActive }),
  customLoaderShown: true,
  setCustomLoaderShown: (customLoaderShown) => set({ customLoaderShown }),
  wordmarkExplosion: false,
  setWordmarkExplosion: (wordmarkExplosion) => set({ wordmarkExplosion }),
  teaserVideoFullscreen: false,
  setTeaserVideoFullScreen: (teaserVideoFullscreen) => set({ teaserVideoFullscreen }),
}));

export default useStore