import * as React from 'react'
import { useProgress } from '@react-three/drei'
import { CSSProperties } from 'react'
import useStore from '../store'

const defaultDataInterpolation = (p) => `${(p * 100).toFixed(0)}%`

const intervalMs = 10

export default function CustomLoader({
  containerStyles,
  innerStyles,
  dataStyles,
  dataInterpolation = defaultDataInterpolation,
  minimumDisplayTime = 1000,
  initialState = true,
}) {
  const { progress } = useProgress()
  const progressRef = React.useRef(0)
  const rafRef = React.useRef(0)
  const progressSpanRef = React.useRef(null)
  const [shown, setShown] = React.useState(initialState)
  const [minimumDisplayTimeProgress, setMinimumDisplayTimeProgress] = React.useState(0)
  const minProgress = Math.min(progress, minimumDisplayTimeProgress)
  const [active, setActive] = React.useState(true)

  const setCustomLoaderActive = useStore(state => state.setCustomLoaderActive)
  const setCustomLoaderShown = useStore(state => state.setCustomLoaderShown)

  const videoRef= React.useRef(null)

  React.useEffect(() => {
    if (!active) {
      setCustomLoaderActive(false)
    }
    if (shown) {
      setCustomLoaderShown(true)
    }
  }, [ active, shown ])

  React.useEffect(() => {
    let t = setInterval(() => {
      if (minimumDisplayTimeProgress < 100) {
        setMinimumDisplayTimeProgress(prevProgress => {
          const step = intervalMs / minimumDisplayTime
          let progress = Math.min(prevProgress + step, 1.0)
          if (progress >= 1.0) {
            clearInterval(t)
            progress = 1.0
            // setActive(false)
          }
          return progress
        })
      }
    }, intervalMs)

    return () => {
      clearInterval(t)
    }
  }, [])

  React.useEffect(() => {
    let t
    if (active !== shown) t = setTimeout(() => setShown(active), 300)
    return () => clearTimeout(t)
  }, [shown, active])

  const updateProgress = React.useCallback(() => {
    if (!progressSpanRef.current) return

    progressRef.current += (minProgress - progressRef.current) / 2
    if (progressRef.current > 0.95 * minProgress || minProgress === 100) progressRef.current = minProgress
    progressSpanRef.current.innerText = dataInterpolation(progressRef.current)
    if (progressRef.current < minProgress) rafRef.current = requestAnimationFrame(updateProgress)
  }, [dataInterpolation, minProgress])

  React.useEffect(() => {
    updateProgress()
    return () => cancelAnimationFrame(rafRef.current)
  }, [updateProgress])

  const autoPause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
    }
  }

  React.useEffect(() => {
    videoRef.current.addEventListener('play', autoPause)

    return () => {
      videoRef.current.removeEventListener('play', autoPause)
    }
  }, [])

  React.useEffect(() => {
    let raf
    
    if (videoRef.current) {
      const newTime = videoRef.current.duration * minProgress

      const videoStep = () => {
        if (!videoRef.current) return
        const distance = newTime - videoRef.current.currentTime
        const increment = distance * 0.05

        if (increment >= 0) {
          if (Math.abs(distance) > 0.01) {
            videoRef.current.currentTime += increment
            raf = requestAnimationFrame(videoStep)
          } else {
            videoRef.current.currentTime = newTime
          }
        }
        if (videoRef.current.currentTime >= videoRef.current.duration) {
          setActive(false)
        }
      }

      raf = requestAnimationFrame(videoStep)
    }

    return () => {
      cancelAnimationFrame(raf)
    }
  }, [minProgress])

  window.videoRef  = videoRef

  return shown ? (
    <div style={{ ...styles.container, opacity: active ? 1 : 0, ...containerStyles }}>
      <div className='wrapper' style={{ ...styles.wrapper }}>
        {/* <p className='callout' style={{ ...styles.callout }}>In the realm of invention,<br /> play is king.</p> */}
        <video className='video' style={{ ...styles.video }} muted autoPlay playsInline ref={videoRef}
          poster='_vite/V5Start.png'
        >
          <source src='_vite/WebsiteLoadingV50001-0200.mp4' type='video/mp4' />
        </video>
        <div style={{ ...styles.inner, ...innerStyles }}>
          <p ref={progressSpanRef} style={{ ...styles.data, ...dataStyles }} />
        </div>
      </div>
    </div>
  ) : null
}

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100dvh',
    minHeight: '100vh',
    background: 'black',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    transition: 'opacity 300ms ease',
    zIndex: 1000,
  },
  callout: {
    fontSize: '22px',
    lineHeight: '1.2',
    textAlign: 'center',
    color: '#F8F5DD',
    paddingBottom: '256px',
    height: '310px',
  },
  video: {
    width: '960px',
    maxWidth: '100vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '105px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  data: {
    position: 'relative',
    fontVariantNumeric: 'tabular-nums',
    marginTop: '0.8em',
    color: '#f0f0f0',
    fontSize: '13px',
    textAlign: 'center',
    lineHeight: '1.4',
    fontFamily: 'Manrope, sans-serif',
    whiteSpace: 'nowrap',
    color: '#F8F5DD',
    margin: '0 0 28px 0',
  },
}
