import { React, useRef, useEffect, useState, useMemo, Suspense } from "react"
import * as THREE from "three"
import { useThree, useFrame, useLoader } from "@react-three/fiber"
import { useGLTF, useAnimations, useTexture } from "@react-three/drei"

import { StaticPage } from "../components/Pages"
import { useScroll } from "../components/ScrollControls"
import { update } from "three/examples/jsm/libs/tween.module.js"

gsap.registerPlugin(ScrollToPlugin)

function VideoPlane({
  startOffset,
  mobileUp = new THREE.Vector3(0, -1, 0),
  desktopUp = new THREE.Vector3(1, 0, 0),
  up = new THREE.Vector3(0, 1, 0),
  vec3 = new THREE.Vector3,
  quat = new THREE.Quaternion,
  ...props
}) {
  const [scale, setScale] = useState(4.7)
  const [positionY, setPositionY] = useState(0)
  const { camera, viewport } = useThree()
  const { size } = useThree()
  const scroll = useScroll()

  const [hovered, setHovered] = useState(false)

  const planeMaterialRef = useRef()

  const meshRef = useRef()

  const pathRef = useRef()
  const transformRef = useRef()
  const planeRef = useRef()
  
  const buttonMaterialRef = useRef()

  const { nodes, animations } = useGLTF("/_vite/models/Airplane19.glb")

  const { actions } = useAnimations(animations, planeRef)

  const scrolledDown = useRef(false)

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])

  useEffect(() => {
    actions.Animation.setLoop(THREE.LoopOnce)
    actions.Animation.clampWhenFinished = true
    void (actions.Animation.reset().play().paused = true)
  }, [actions])

  const curve = useMemo(() => {
    const depth = -0.5
    const depthViewport = viewport.getCurrentViewport(camera, [0,0,depth])
    if (size.width < 768 || size.height < 768) {
      return new THREE.CatmullRomCurve3([
        new THREE.Vector3(0, positionY, 0),
        new THREE.Vector3(depthViewport.width / 2 + scale, positionY, -0.5),
      ], false, 'chordal')
    } else {
      return new THREE.CatmullRomCurve3([
        new THREE.Vector3(0, positionY, 0),
        new THREE.Vector3(1, positionY, 0),
        new THREE.Vector3(depthViewport.width / 2 + scale + 1, positionY - 0.5, -0.5),
      ], false, 'chordal')
    }
  }, [size.width, size.height, viewport.width])

  const points = useMemo(() => {
    return curve.getPoints(64)
  }, [curve])

  const startPosition = startOffset + 0.25
  const endPosition = startOffset + 1.25

  useFrame(() => {
    const position = scroll.offset * (scroll.pages - 1)
    let animationProgress = 0
    let pathProgress = 0

    if (position < startOffset) {
      transformRef.current.position.y = 0
    }
    else if (position > endPosition) {
      transformRef.current.position.y = -viewport.height * (endPosition - 1)
      animationProgress = 1
      pathProgress = 1
    }
    else {
      transformRef.current.position.y = -viewport.height * (position - startOffset)
    }

  if (position > startPosition && position < endPosition) {
      animationProgress = (position - startPosition) / (endPosition - startPosition)
      pathProgress = Math.max(animationProgress * 2 - 1, 0)
      animationProgress = Math.min(animationProgress * 2, 1)
      if (size.width < 768 || size.height < 768) {
        transformRef.current.rotation.x = -Math.PI / 2 * pathProgress
        transformRef.current.rotation.y = Math.PI / 4 * pathProgress
        transformRef.current.rotation.z = Math.PI / 4 * pathProgress
      } else {
        transformRef.current.rotation.x = -Math.PI / 4 * pathProgress
        transformRef.current.rotation.y = 0
        transformRef.current.rotation.z = 0
      }
    }
    
    // set animation time to animationProgress
    actions.Animation.time = actions.Animation.getClip().duration * animationProgress
    buttonMaterialRef.current.opacity = 1 - Math.min(animationProgress * 2, 1)

    // TEMP
    if (position > startPosition && !scrolledDown.current) {
      scrolledDown.current = true
      gsap.to(
        scroll.el, {
        duration: 2,
        scrollTo: {
          y: (startOffset + 1.5) * (scroll.el.clientHeight * (1 + scroll.damping)),
        },
        ease: 'linear'
      })
    }

    if (position < startPosition && scrolledDown.current) {
      scrolledDown.current = false
    }

    const point = curve.getPointAt(pathProgress)
    const tan = curve.getTangent(pathProgress).normalize()
    if (size.width < 768 || size.height < 768) {
      quat.setFromUnitVectors(mobileUp, vec3.set(0, 0, 0))
    } else {
      quat.setFromUnitVectors(desktopUp, vec3.copy(tan))
    }

    pathRef.current.position.copy(point)
    // pathRef.current.quaternion.copy(quat)
  })

  useEffect(() => {
    let newScale, width, height

    if (size.width < 768 || size.height < 768) {
      width = Math.min(size.width - 28, (size.height - 180) * (1 / 1.414))
      newScale = width / size.width * 1.414 * viewport.width
      height = newScale / 1.414
    } else {
      width = Math.min(size.width - 28, 915)
      newScale = width / size.width * viewport.width
      height = newScale / 1.414
    }

    const pixelsPerVh = viewport.width / size.width

    if (height > viewport.height - (180 * pixelsPerVh)) {
      setPositionY(-90 * pixelsPerVh + ((viewport.height - height) / 2))
    } else {
      setPositionY(0)
    }

    if (scale !== newScale) {
      setScale(newScale)
  
      updateTexture()
    }
  }, [size.width, size.height, viewport.width])

  const [video] = useState(() =>
    Object.assign(document.createElement("video"), {
      // src: "https://player.vimeo.com/progressive_redirect/playback/795761754/rendition/1080p/file.mp4?loc=external&signature=be09662fd46a2231c891e52068cde2a9806a403176292e98730893b525361524", // 1080p
      src: "https://player.vimeo.com/progressive_redirect/playback/795761754/rendition/540p/file.mp4?loc=external&signature=15f103314b1e9ec02b2e4e7e4c3f6f1bcf7cbdabca2964d93e8aaaaae7aa29b5", // 540p
      crossOrigin: "Anonymous",
      poster: "/_vite/ParadowskiXRPoster.jpg",
      loop: true,
      muted: true,
      autoplay: true,
      playsInline: true,
    })
  )

  const [ playing, setPlaying ] = useState(false)

  const onPlaying = () => {
    setPlaying(true)
    updateTexture()
  }
  
  useEffect(() => {
    video.addEventListener('playing', onPlaying)
    void video.play()

    return () => {
      video.removeEventListener('playing', onPlaying)
    }
  }, [video])

  const updateTexture = () => {
    if (size.width < 768 || size.height < 768) {
      planeMaterialRef.current.map.channel = 1
    } else {
      planeMaterialRef.current.map.channel = 0
    }

    planeMaterialRef.current.needsUpdate = true
  }

  const buttonShape = useMemo(() => {
    const circle = new THREE.Shape()

    const radius = 1
    const segments = 64

    circle.moveTo(radius, 0)

    for (let i = 1; i <= segments; i++) {
      const theta = (i / segments) * Math.PI * 2
      circle.lineTo(Math.cos(theta) * radius, Math.sin(theta) * radius)
    }

    circle.closePath()

    const triangle = new THREE.Shape()

    const triangleRadius = 0.5
    const angle = Math.PI * 2 / 3;

    triangle.moveTo(
      Math.cos(0) * triangleRadius,
      Math.sin(0) * triangleRadius
    )
    triangle.lineTo(
      Math.cos(angle) * triangleRadius,
      Math.sin(angle) * triangleRadius
    )
    triangle.lineTo(
      Math.cos(2 * angle) * triangleRadius,
      Math.sin(2 * angle) * triangleRadius
    )
    triangle.closePath()

    circle.holes.push(triangle)

    return circle
  })

  const PlaneVideoTexture = useMemo(() => {
    const videoTexture =  new THREE.VideoTexture(video)
    videoTexture.colorSpace = THREE.SRGBColorSpace
    videoTexture.flipY = false
    videoTexture.wrapS = THREE.RepeatWrapping
    videoTexture.wrapT = THREE.RepeatWrapping

    if (size.width < 768 || size.height < 768) {
      videoTexture.channel = 1
    } else {
      videoTexture.channel = 0
    }

    return videoTexture
  }, [video])

  const PlanePosterTexture = useMemo(() => {
    const posterTexture = new THREE.TextureLoader().load("/_vite/ParadowskiXRPoster.jpg")
    posterTexture.flipY = false
    posterTexture.wrapS = THREE.RepeatWrapping
    posterTexture.wrapT = THREE.RepeatWrapping

    if (size.width < 768 || size.height < 768) {
      posterTexture.channel = 1
    } else {
      posterTexture.channel = 0
    }

    return posterTexture
  }, [])

  return (
    <group ref={pathRef}>
      <group ref={transformRef}>
        <mesh scale={0.36}>
          <shapeGeometry args={[buttonShape]} />
          <meshBasicMaterial color="white" ref={buttonMaterialRef} transparent />
        </mesh>
        <group position-y={positionY}>
          <group scale={1 / 0.2835}>
            <group
              ref={planeRef}
              scale={scale}
              rotation-x={Math.PI / 2}
              rotation-y={(size.width > 768 && size.height > 768) ? -Math.PI / 2 : -Math.PI}
              rotation-z={Math.PI}
              onPointerOver={() => setHovered(true)}
              onPointerOut={() => setHovered(false)}
              onClick={() => {
                document.getElementById('video-placeholder').click()
              }}
            >
              <skinnedMesh
                castShadow
                receiveShadow
                geometry={nodes.Plane.geometry}
                skeleton={nodes.Plane.skeleton}
              >
                <Suspense fallback={null}>
                  <meshBasicMaterial
                    ref={planeMaterialRef}
                    attach="material"
                    side={THREE.DoubleSide}
                    map={playing ? PlaneVideoTexture : PlanePosterTexture}
                  />
                </Suspense>
              </skinnedMesh>
              <primitive object={nodes.Root} />
              <primitive object={nodes.neutral_bone} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}


export default function Teaser({ startOffset }) {
  return (
    <StaticPage startOffset={startOffset}>
      <VideoPlane startOffset={startOffset} />
    </StaticPage>
  )
}

useGLTF.preload("/_vite/models/Airplane19.glb")
