import { React, useRef, useEffect, useState } from "react";
import { StaticHtmlPage } from "./Pages";

import useStore from '../store'

export default function TeaserHtml({ startOffset }) {
  const vimeoRef = useRef();
  const { setTeaserVideoFullScreen } = useStore();

  useEffect(() => {
    let player = new Vimeo.Player("{video01_name}", { id: 795761754 });
    vimeoRef.current = player;

    player.ready().then(function () {
      player.element.style.display = "none";

      player.on("fullscreenchange", ({ fullscreen }) => {
        if (fullscreen) {
          player.element.style.display = "block";
          player.play();
          setTeaserVideoFullScreen(true);
        } else {
          setTimeout(() => {
            player.element.style.display = "none";
            player.pause();
            setTeaserVideoFullScreen(false);
          }, 100);
        }
      });
    });
  }, []);

  const playVideo = () => {
    let player = vimeoRef.current;
    if (vimeoRef.current) {
      player.requestFullscreen();
      player.play();
    }
  };

  return (
    <StaticHtmlPage startOffset={startOffset}>
      <section className="teaser inner-wrapper" id="demo">
        <div className="content">
          <button
            id="video-placeholder"
            className="video-placeholder"
            aria-label="Play demo reel video in full screen"
            title="play demo reel video"
            onClick={playVideo}
          >
          </button>
        </div>
      </section>
    </StaticHtmlPage>
  );
}
