import { StaticHtmlPage } from './Pages'
import useStore from '../store'

export default function FloatingWordmarkHtml({ startOffset }) {
  const { wordmarkExplosion } = useStore()

  return (
    <StaticHtmlPage startOffset={startOffset}>
      <section className='floating-wordmark inner-wrapper' id="intro">
        <div className={`heading opacity-ease ${wordmarkExplosion ? '' : 'hidden'}`}>
          <h1 className='h1 loading-text'>
            We make immersive, shareable brand experiences that&nbsp;demand
            attention.
          </h1>
          <p className='subhead loading-text'>
            Hello. We’re
            <br className='mobile-only' />
            <span className='nowrap'> /ˈper.ə.daʊ.skiː/.</span>
          </p>
        </div>
      </section>
    </StaticHtmlPage>
  )
}
