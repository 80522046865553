import * as THREE from 'three'
import { useRef, useState, useEffect, useLayoutEffect, useMemo, Suspense } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Environment, Html, useGLTF, useTexture } from '@react-three/drei'
import { Physics, RigidBody, InstancedRigidBodies } from '@react-three/rapier'

import useStore from '../store'
import { useScroll } from '../components/ScrollControls'

import { StickyPage } from '../components/Pages'

const scale = 2

let letterQueueEmpty = true
let letterQueueInterval

const letterNodes = [
  {
    name: 'Letter-P',
    x: -3.553,
  },
  {
    name: 'Letter-A',
    x: -2.814,
  },
  {
    name: 'Letter-R',
    x: -2.032,
  },
  {
    name: 'Letter-A-2',
    x: -1.223,
  },
  {
    name: 'Letter-D',
    x: -0.447,
  },
  {
    name: 'Letter-O',
    x: 0.393,
  },
  {
    name: 'Letter-W',
    x: 1.294,
  },
  {
    name: 'Letter-S',
    x: 2.157,
  },
  {
    name: 'Letter-K',
    x: 2.907,
  },
  {
    name: 'Letter-I',
    x: 3.529,
  },
]

const clientObjects = [
  'AirplaneProp',
  'Alien',
  'Basketball',
  'Brush',
  'Cheezit',
  'Chicken',
  'Dragon',
  'Elmo',
  'Eyes',
  'Golfball',
  'Grandpa',
  'HorseParty',
  'Mailboxes',
  'Phone',
  'Phonebooth',
  'Pig',
  'TV',
  'Tennisball',
  'Tiger',
  'Tomatos',
]

const shuffle = arr => arr
  .map(a => [Math.random(), a])
  .sort((a, b) => a[0] - b[0])
  .map(a => a[1])

const modelsArray = shuffle(clientObjects)

function ClientObject({ modelPath, incrementModel, active, vec = new THREE.Vector3() }) {
  const { camera, viewport } = useThree()
  const rigidBody = useRef()
  const primitiveRef = useRef()
  const minDepth = 0
  const maxDepth = -2

  const incrementingModel = useRef(false)

  const randomizePosition = (floor = false) => {
    gltf.scene.traverse((child) => {
      child.visible = false
    })

    const zPos = (Math.random() * (maxDepth + 1)) - 1

    const { width: zWidth, height: zHeight } = viewport.getCurrentViewport(camera, [0, 0, zPos])

    const xPos = (Math.random() - 0.5) * (zWidth - scale)

    let yPos = (Math.random() - 0.5) - (zHeight / 2)

    if (floor) {
      yPos = (-zHeight / 2) - scale
      rigidBody.current.setRotation({
        w: 1.0,
        x: (Math.random() - 0.5) / 2,
        y: (Math.random() - 0.5) / 2,
        z: (Math.random() - 0.5) / 2,
      })
    }

    vec.set(
      xPos,
      yPos,
      zPos
    )

    rigidBody.current.setTranslation(vec)

    // prevent translation from changing outside of frame loop
    requestAnimationFrame(() => {
      gltf.scene.traverse((child) => {
        child.visible = true
      })
      randomizeVelocity()
    })
  }

  const randomizeVelocity = () => {
    rigidBody.current.resetForces()

    rigidBody.current.setLinvel({
      x: 0,
      y: 1,
      z: 0
    }, true)


    requestAnimationFrame(() => {
      rigidBody.current.setAngvel({
        x: (Math.random() - 0.5) * 0.25,
        y: (Math.random() - 0.5) * 1.0,
        z: (Math.random() - 0.5) * 0.25,
      })
    })
  }

  useEffect(() => {
    incrementingModel.current = false
    randomizePosition(true)
  }, [modelPath])

  useFrame(() => {
    if (rigidBody.current && active) {
      const translation = rigidBody.current.translation()
      const linvel = rigidBody.current.linvel()
      const mass = rigidBody.current.mass()
      const { width: zWidth, height: zHeight } = viewport.getCurrentViewport(camera, [0, 0, translation.z])

      // reset if out of x || y bounds
      if (
        !incrementingModel.current && (
          Math.abs(translation.x) > (zWidth / 2 + scale) ||
          translation.y > (zHeight / 2 + scale) ||
          translation.y < (-zHeight / 2 - (scale * 2))
        )
      ) {
        incrementingModel.current = true
        incrementModel()
      }

      // dampen z linear velocity if in bounds
      if (translation.z > minDepth) {
        rigidBody.current.applyImpulse({
          x: 0,
          y: 0,
          z: -0.1 * mass,
        })
      } else if (translation.z < maxDepth) {
        rigidBody.current.applyImpulse({
          x: 0,
          y: 0,
          z: 0.1 * mass,
        })
      } else if (linvel.z > 0.1) {
        rigidBody.current.setLinvel({
          x: linvel.x,
          y: linvel.y,
          z: linvel.z / 2,
        })
      }

      // maintain y velocity
      if (linvel.y < 0.5) {
        rigidBody.current.applyImpulse({
          x: 0,
          y: 0.1 * mass,
          z: 0
        }, true)
      }
    }
  })

  const gltf = useGLTF(modelPath)

  return (
    <RigidBody
      ref={rigidBody}
      angularDamping={0.1}
      colliders='hull'
      canSleep={false}
      mass={1}
      onPointerOver={(e) => {
        rigidBody.current.applyImpulse({
          x: 0,
          y: 1.5 * rigidBody.current.mass(),
          z: 0,
        })
      }}
    >
      <primitive object={gltf.scene} ref={primitiveRef}/>
    </RigidBody>
  )
}

function InstancedLetters({ wordmarkScale, active }) {
  const { nodes } = useGLTF('/_vite/models/ParadowskiCondensed.glb')

  return (
    <group>
      {letterNodes.map(({ name, x }, i) => (
        <InstancedLetter
          key={`${name}_i`}
          nodeName={name}
          x={x}
          geometry={nodes[name].geometry}
          wordmarkScale={wordmarkScale}
          active={active}
        />
      ))}
    </group>
  )
}

const COUNT = 3

function InstancedLetter({ nodeName, x, geometry, active, wordmarkScale, vec = new THREE.Vector3() }) {
  const rigidBodies = useRef()
  const { camera, viewport, size } = useThree()
  const { wordmarkExplosion } = useStore()

  const minDepth = 0
  const maxDepth = -camera.position.z * 1.5

  const instances = useMemo(() => {
    const instances = [];

    for (let i = 0; i < COUNT; i++) {
      const key = 'instance_' + i
      if (i === 0) {
        instances.push({
          key,
          position: [x * wordmarkScale * 0.112, 0, -1],
          scale: wordmarkScale * 0.112,
        })
      } else {
        instances.push({
          key,
          position: [Math.random() * 100000, -100, -2],
          rotation: [Math.random(), Math.random(), Math.random()],
          scale,
        })
      }
    }

    return instances
  }, [])

  useEffect(() => {
    if (wordmarkExplosion) {
      const rigidBody = rigidBodies.current[0]
      const mass = rigidBody.mass()
      // random impulse
      rigidBody.applyImpulse({
        x: (Math.random() - 0.5) * mass,
        y: (Math.random() + 0.25) * mass,
        z: (-Math.random()) * mass * 0.1,
      }, true)
      rigidBody.applyTorqueImpulse({
        x: (Math.random() - 0.5) * mass * 0.01,
        y: (Math.random() - 0.5) * mass * 0.01,
        z: (Math.random() - 0.5) * mass * 0.01,
      }, true)

      rigidBodies.current.forEach((_, i) => {
        if (i > 0) {
          randomizePosition(rigidBodies.current[i], true, nodeName)
        }
      })
    }

  }, [wordmarkExplosion])

  const randomizePosition = (rigidBody, floor = false, nodeName = '') => {
    if (letterQueueEmpty) {
      letterQueueEmpty = false
      clearInterval(letterQueueInterval)
      letterQueueInterval = setInterval(() => letterQueueEmpty = true, 750)
    } else {
      return
    }

    if (floor) {
      const zPos = (Math.random() * (maxDepth + 1)) - 1

      const { width: zWidth, height: zHeight } = viewport.getCurrentViewport(camera, [0, 0, zPos])

      const xPos = (Math.random() - 0.5) * (zWidth + scale)

      let yPos = (Math.random() - 0.5) * (zHeight + scale)

      if (floor) {
        yPos = (-zHeight / 2) - scale
      } else {
        rigidBody.setRotation({
          w: 1.0,
          x: (Math.random() - 0.5),
          y: (Math.random() - 0.5),
          z: (Math.random() - 0.5),
        })
      }

      vec.set(
        xPos,
        yPos,
        zPos
      )

      rigidBody.setTranslation(vec)
    }

    if (wordmarkExplosion) {
      rigidBody.setLinvel({
        x: 0,
        y: 1,
        z: 0
      }, true)
  
      requestAnimationFrame(() => {
        rigidBody.setAngvel({
          x: (Math.random() - 0.5) * 0.25,
          y: (Math.random() - 0.5) * 0.5,
          z: (Math.random() - 0.5) * 0.25,
        }, true)
      })
    }
  }

  useFrame(() => {
    rigidBodies.current.forEach((_, i) => {
      const rigidBody = rigidBodies.current[i]

      if (rigidBody && wordmarkExplosion) {
        const translation = rigidBody.translation()
        const linvel = rigidBody.linvel()
        const mass = rigidBody.mass()
        const { width: zWidth, height: zHeight } = viewport.getCurrentViewport(camera, [0, 0, translation.z])
        if (!zWidth || !zHeight) {
          console.info(nodeName, i)
        }

        // reset if out of x || y bounds
        if (
          Math.abs(translation.x) > (zWidth / 2 + scale) ||
          translation.y > (zHeight / 2 + scale) ||
          translation.y < (-zHeight / 2 - (scale * 2))
        ) {
          rigidBody.resetForces()
          rigidBody.resetTorques()
          rigidBody.setLinvel({
            x: 0,
            y: 0,
            z: 0
          })
          rigidBody.setAngvel({
            x: 0,
            y: 0,
            z: 0
          })
          if (i === 0) {
            vec.set(
              Math.random() * 100000,
              100,
              -1
            )
            rigidBody.setTranslation(vec)
          }
          else if (active) {
            randomizePosition(rigidBody, true, nodeName)
          }
        } else {
          // dampen z linear velocity if in bounds
          if (translation.z > minDepth) {
            rigidBody.applyImpulse({
              x: 0,
              y: 0,
              z: -0.1 * mass,
            })
          } else if (translation.z < maxDepth) {
            rigidBody.applyImpulse({
              x: 0,
              y: 0,
              z: 0.1 * mass,
            })
          } else if (linvel.z > 0.1) {
            rigidBody.setLinvel({
              x: linvel.x,
              y: linvel.y,
              z: linvel.z / 2,
            })
          }
  
          // maintain y velocity
          if (linvel.y < 0.5 && wordmarkExplosion) {
            rigidBody.applyImpulse({
              x: 0,
              y: 0.1 * mass,
              z: 0
            }, true)
          }
        }
      }
    })
  })

  return (
    <InstancedRigidBodies
      ref={rigidBodies}
      instances={instances}
      colliders='hull'
    >
      <instancedMesh
        args={[undefined, undefined, COUNT]}
        count={COUNT}
        geometry={geometry}
        onPointerOver={(e) => {
          if (e.instanceId !== 0) {
            const rigidBody = rigidBodies.current[e.instanceId]
            rigidBody.applyImpulse({
              x: 0,
              y: 1.5 * rigidBody.mass(),
              z: 0,
            })
          }
        }}
      >
        <meshStandardMaterial color='black' roughness={0.5} fog={false}/>
      </instancedMesh>
    </InstancedRigidBodies>
  )
}

function WordmarkPlaceholder({ wordmarkExplosion, ...props }) {
  const texture = useTexture('/_vite/V5End.png')
  // TEMP
  const materialRef = useRef()
  const maxOpacity = 1

  useFrame(() => {
    if(wordmarkExplosion) {
      if (materialRef.current.opacity > 0) {
        materialRef.current.opacity = Math.max(materialRef.current.opacity - 0.1, 0)
      }
    }
  })

  return (
    <mesh
      {...props}
    >
      <planeGeometry args={[1, 0.15625]}/>
      <meshBasicMaterial opacity={1} transparent map={texture} ref={materialRef} />
    </mesh>
  )
}

export default function FloatingWordmark({ startOffset, endOffset }) {
  const scroll = useScroll()
  const { camera, viewport, size } = useThree()
  const [scrolled, setScrolled] = useState(false)
  const [active, setActive] = useState(true)

  const minDepth = 0.5
  const maxDepth = -camera.position.z * 1.5 + - 1

  // client objects
  const modelIndex = useRef(0)
  const [modelPath, setModelPath] = useState(`/_vite/models/clientObjects/${modelsArray[modelIndex.current]}.glb`)

  const incrementModel = () => {
    modelIndex.current = (modelIndex.current + 1) % modelsArray.length
    setModelPath(`/_vite/models/clientObjects/${modelsArray[modelIndex.current]}.glb`)
    useGLTF.preload(`/_vite/models/clientObjects/${modelsArray[modelIndex.current + 1]}.glb`)
  }

  const { customLoaderActive, wordmarkExplosion, setWordmarkExplosion } = useStore()

  useFrame(() => {
    const position = scroll.offset * (scroll.pages - 1)

    if (!scrolled && scroll.offset) {
      setScrolled(true)
    }

    if (position < startOffset) {
      if (active) {
        setActive(false)
      }
    } else if (position > startOffset && position < endOffset) {
      if (!active) {
        setActive(true)
      }
    } else if (position > endOffset) {
      if (active) {
        setActive(false)
      }
    }
  })

  useGLTF.preload(modelPath)
  useGLTF.preload(`/_vite/models/clientObjects/${modelsArray[modelIndex.current + 1]}.glb`)

  // wordmark scaling
  const [wordmarkScale, setWordmarkScale] = useState(1)
  const letterDepth = 0.397117
  const zPos = -1 + (letterDepth / 2)
  const objectDepth = 0

  useLayoutEffect(() => {
    const desiredSizeInPixels = Math.min(960, size.width)
    const nearZ = zPos - (objectDepth / 2)
    const distance = Math.abs(camera.position.z - nearZ)
    const fovInRadians = (camera.fov * Math.PI) / 180
    const heightAtZ = 2 * distance * Math.tan(fovInRadians / 2)
    const widthAtZ = heightAtZ * camera.aspect
    const pixelsPerUnit = size.width / widthAtZ
    const desiredSizeInUnits = desiredSizeInPixels / pixelsPerUnit
    setWordmarkScale(desiredSizeInUnits)
  }, [camera, viewport, size])

  const initialRender = useRef(true)

  // design requesed automatic wordmark explosion
  // useEffect(() => {
  //   if (wordmarkExplosion) return
  //   if (initialRender.current) {
  //     initialRender.current = false
  //   } else {
  //     setWordmarkExplosion(true)
  //   }
  // }, [active, viewport, scrolled, wordmarkExplosion])

  useEffect(() => {
    requestAnimationFrame(() => {
      if (!customLoaderActive && !wordmarkExplosion) {
        setWordmarkExplosion(true)
      }
    })
  }, [wordmarkExplosion, customLoaderActive])

  return (
    <StickyPage startOffset={startOffset} endOffset={endOffset}>
      {!wordmarkExplosion && (
        <WordmarkPlaceholder scale={wordmarkScale} position-z={zPos} />
      )}
      { !customLoaderActive && (
        <Physics gravity={[0, 0, 0]} interpolation={false} colliders={false}>
          <InstancedLetters wordmarkScale={wordmarkScale} active={active} />
          {wordmarkExplosion && (
            <ClientObject 
              modelPath={modelPath}
              incrementModel={incrementModel}
              active={active}
            />
          )}
          <RigidBody
            colliders='cuboid'
            includeInvisible
          >
            <mesh scale={100} position-z={maxDepth}>
              <planeGeometry/>
              <meshBasicMaterial visible={false} />
            </mesh>
          </RigidBody>
          <RigidBody
            colliders='cuboid'
            includeInvisible
          >
            <mesh scale={100} position-z={minDepth}>
              <planeGeometry />
              <meshBasicMaterial visible={false}/>
            </mesh>
          </RigidBody>
        </Physics>
      )}
      <Environment files='/_vite/textures/studio_small_04_05k2W.hdr' />
    </StickyPage>
  )
}

useGLTF.preload('/_vite/models/ParadowskiCondensed.glb')
